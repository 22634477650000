import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import getConfig from 'next/config';
import cookie from 'js-cookie';

interface BaseResponse<ResponseType = any> {
  status: number;
  message: string;
  data: ResponseType;
}
class Api {
  private API_URL = getConfig().publicRuntimeConfig.API_NOBO_URL;

  private api = axios.create({
    baseURL: this.API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      who: 'manager',
    },
    timeout: 30000,
  });

  constructor() {
    this.api.interceptors.request.use((config) => {
      if (!config.headers.apiToken
        || !config.headers.apiToken.length
        || config.headers.apiToken.length < 3) {
        // eslint-disable-next-line no-param-reassign
        config.headers.apiToken = cookie.get('apiToken') || null;
      }
      return config;
    });

    /* this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          store.dispatch({ type: 'FLUSH' });
          persistor.persist();
        }
        return Promise.reject(error);
      },
    ); */
  }

  call = (config: AxiosRequestConfig): Promise<AxiosResponse<BaseResponse>> => this.api(config);

  ping = (): Promise<AxiosResponse> => this.api.get('/ping', { timeout: 1500 });

  auth = {
    login: (email: string, password: string): Promise<AxiosResponse<BaseResponse<AuthResponse>>> => this.api.post('/auth/authenticate', { email, password }),
    checkToken: (apiToken?: string): Promise<AxiosResponse<BaseResponse>> => this.api.get('/token/isValid/apiToken', { headers: { apiToken: apiToken || '' } }),
  };

  configuration = {
    conf: (name: string, apiToken?: string): Promise<AxiosResponse<BaseResponse<string>>> => this.api.get('/manager/conf', { params: { name }, headers: { apiToken: apiToken || '' } }),
    setConf: (name: string, value: string): Promise<AxiosResponse<BaseResponse>> => this.api.put('/manager/conf', { conf: { value } }, { params: { name } }),
    list: (name: string, deleted = 0): Promise<AxiosResponse<BaseResponse<ConfListItem[]>>> => this.api.get(`/manager/list?listName=${name}&deleted=${deleted}`),
  };

  mission = (id?: number) => ({
    cancel: (data: {
      toCharge: boolean,
      who?: 'customer' | 'provider' | 'nobo' | undefined,
      reason?: string | undefined,
    }) => this.api.post(`/manager/mission/${id}/cancel`, data),
  });

  transaction = (id?: number) => ({
    invoice: (): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/unpaid-invoices/transaction/${id}/invoice`),
    refund: (amount: number): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/transaction/${id}/refund`, { amount }),
    cancel: () => this.api.post(`/manager/unpaid-invoices/transaction/${id}/cancel`),
    document: {
      generate: (): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/transaction/${id}/invoice`),
      download: (): Promise<AxiosResponse<BaseResponse<string>>> => this.api.get(`/manager/transaction/${id}/invoice`),
      delete: (): Promise<AxiosResponse<BaseResponse>> => this.api.delete(`/manager/transaction/${id}/invoice`),
    },
  });

  customer = (id?: number) => ({
    address: (addressId: number) => ({
      task: {
        get: (): Promise<AxiosResponse<BaseResponse<AddressTask>>> => this.api.get(`/manager/customer/${id}/address/${addressId}/task`),
        update: (data: AddressTask): Promise<AxiosResponse<BaseResponse>> => this.api.put(`/manager/customer/${id}/address/${addressId}/task`, data),
      },
    }),
    paymentMeans: {
      get: (): Promise<AxiosResponse<BaseResponse<PaymentMean[]>>> => this.api.get(`/manager/customer/${id}/payment/means`),
    },
    connect: (user_id: number) => this.api.post(`/manager/customer/${user_id}/connect`),
    promocode: {
      check: (code: string): Promise<AxiosResponse<BaseResponse<{ discountAmount: number | null; promoCode: Promocode }>>> => this.api.get(`/manager/customer/${id}/promocode/${code}`),
      apply: (code: string): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/customer/${id}/promocode/${code}`),
    },
    providerHistory: (): Promise<AxiosResponse<BaseResponse<CustomerProviderHistoryItem[]>>> => this.api.get(`/manager/customer/${id}/provider-history`),
    taxReturn: {
      list: (): Promise<AxiosResponse<BaseResponse<TaxReturnResponse>>> => this.api.get(`/manager/documents/customer/${id}/tax-return`),
      year: (year: number) => this.api.get(`/manager/documents/customer/${id}/tax-return/${year}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      }),
      sendByMail: (year: number) => this.api.post(`/manager/documents/customer/${id}/tax-return/${year}/mail`),
      requestYear: (year: number): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/documents/customer/${id}/tax-return/${year}`),
    },
    invoiceSummary: {
      list: (): Promise<AxiosResponse<BaseResponse<InvoiceSummaryItem[]>>> => this.api.get(`/manager/customer/${id}/benefit-summary/document`),
      request: (from: string, to: string): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/customer/${id}/benefit-summary/request`, { from, to }),
      get: (documentId: number): Promise<AxiosResponse<BaseResponse<string>>> => this.api.get(`/manager/customer/${id}/benefit-summary/document/${documentId}`),
    },
    cesu: {
      history: (): Promise<AxiosResponse<BaseResponse<CesuHistoryItem[]>>> => this.api.get(`manager/customer/${id}/cesu-history`),
    },
    search: (search: string): Promise<AxiosResponse<BaseResponse<Array<Customer>>>> => this.api.get('/manager/customer', {
      params: {
        search: search?.trim().normalize(),
      },
    }),
    get: (): Promise<AxiosResponse<BaseResponse<Customer>>> => {
      if (!id) {
        throw new Error('Customer id is required');
      }
      return this.api.get(`/manager/customer/${id}`);
    },
    provider: (provider_id?: number) => ({
      stop: (data: {
        date: string;
        reason: number;
        comment?: string;
      }): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/customer/${id}/stop/provider/${provider_id}`, data),
    }),
    listProviders: (): Promise<AxiosResponse<BaseResponse<ProviderOfACustomer[]>>> => this.api.get(`/manager/customer/${id}/provider`),
    offeredHours: {
      get: (): Promise<AxiosResponse<BaseResponse<number>>> => this.api.get(`/manager/customer/${id}/offered-hours`),
      add: (hours: number): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/customer/${id}/offered-hours`, { hours }),
      delete: (hours: number): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/customer/${id}/offered-hours/delete`, { hours }),
    },
    news: {
      get: (): Promise<AxiosResponse<BaseResponse<CustomerNews[]>>> => this.api.get('/manager/customers/news'),
      add: (data: { title: string, description: string, url?: string }): Promise<AxiosResponse<BaseResponse>> => this.api.post('/manager/customers/news', data),
      delete: (newsId: number): Promise<AxiosResponse<BaseResponse>> => this.api.delete(`/manager/customers/news/${newsId}`),
    },
    suggestions: (): Promise<AxiosResponse<BaseResponse<CustomerSuggestion[]>>> => this.api.get('/manager/customers/suggestions'),
  });

  provider = (id?: number) => ({
    create: (data: CreateProviderRequestBody) => this.api.post('/manager/provider', data),
    stop: (data: {
      date: string;
      reason: number;
      comment?: string;
    }): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/provider/${id}/stop/nobo`, data),
    customer: (customerId: number) => ({
      stop: (data: {
        date: string;
        reason: number;
      }): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/provider/${id}/stop/customer/${customerId}`, data),
    }),
    listCustomers: (): Promise<AxiosResponse<BaseResponse<CustomerOfAProvider[]>>> => this.api.get(`manager/provider/${id}/customer`),
    aed: {
      list: (): Promise<AxiosResponse<BaseResponse>> => this.api.get(`/manager/documents/provider/${id}/aed`),
      request: (end: string): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/documents/provider/${id}/aed?end=${end}`),
      certificate: (certificateId: number): Promise<AxiosResponse<BaseResponse>> => this.api.get(`/manager/documents/provider/${id}/aed/${certificateId}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      }),
    },
    search: (search: string, contract?: ProviderSelectContract, state?: ProviderSelectState): Promise<AxiosResponse<BaseResponse<Array<Provider>>>> => this.api.get('/manager/provider', {
      params: {
        search: search?.trim().normalize(),
        contract,
        state,
      },
    }),
    get: (): Promise<AxiosResponse<BaseResponse<Provider>>> => {
      if (!id) {
        throw new Error('Provider id is required');
      }
      return this.api.get(`/manager/provider/${id}`);
    },
    urssaf: {
      get: (): Promise<AxiosResponse<BaseResponse<ProviderUrssaf>>> => this.api.get(`/manager/provider/${id}/urssaf`),
      update: (data: ProviderUrssaf): Promise<AxiosResponse<BaseResponse>> => this.api.put(`/manager/provider/${id}/urssaf`, data),
    },
    availabilites: {
      get: (): Promise<AxiosResponse<BaseResponse<Availability[]>>> => this.api.get(`/manager/provider/${id}/availabilities`),
      update: (availability: Availability[]): Promise<AxiosResponse<BaseResponse>> => this.api.put(`/manager/provider/${id}/availabilities`, { availability }),
    },
    planningSummary: {
      currentWeek: (year: number, week: number): Promise<AxiosResponse<BaseResponse<ProviderWeeklyPlanningSummary>>> => this.api.get(`/manager/provider/${id}/planning-summary/current-week`, {
        params: {
          weekNumber: week,
          yearNumber: year,
        },
      }),
    },
  });

  rgpd = {
    user: (id: number) => ({
      suppress: (): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/RGPD/user/${id}/suppress`),
    }),
  };

  address = (addressId?: number) => ({
    key: (keyId?: number) => ({
      get: (): Promise<AxiosResponse<BaseResponse<AddressKey[]>>> => this.api.get(`/manager/address/${addressId}/key/`),
      add: (description?: string, tagName?: string): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/address/${addressId}/key`, { description, tagName }),
      delete: (): Promise<AxiosResponse<BaseResponse>> => this.api.delete(`/manager/address/${addressId}/key/${keyId}`),
      history: {
        get: (): Promise<AxiosResponse<BaseResponse<AddressKeyHistoryItem[]>>> => this.api.get(`/manager/address/${addressId}/key/${keyId}/history`),
        add: (data: AddressKeyHistoryRequest): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/address/${addressId}/key/${keyId}/history`, data),
      },
    }),
    checklist: (checklistId?: number) => ({
      get: (): Promise<AxiosResponse<BaseResponse<AddressChecklistItem[]>>> => this.api.get(`/manager/address/${addressId}/checklist`),
      add: (task: string): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/address/${addressId}/checklist`, { task }),
      delete: (): Promise<AxiosResponse<BaseResponse>> => this.api.delete(`/manager/address/${addressId}/checklist/${checklistId}`),
      history: {
        get: (): Promise<AxiosResponse<BaseResponse<AddressChecklistHistoryItem[]>>> => this.api.get(`/manager/address/${addressId}/checklist/${checklistId}/history`),
      },
    }),
  });

  user = (userId?: number) => ({
    updateEmail: (email: string): Promise<AxiosResponse<BaseResponse>> => this.api.put(`/manager/user/${userId}/email`, { email }),
    updatePhone: (phone: string): Promise<AxiosResponse<BaseResponse>> => this.api.put(`/manager/user/${userId}/phone`, { phone }),
  });

  event = {
    waiting: {
      stats: (): Promise<AxiosResponse<BaseResponse<EventWaitingStats>>> => this.api.get('/manager/events-waiting/stats'),
      get: (
        start: string,
        end: string,
        filter: 'all' | 'punctual' | 'recurrent',
      ): Promise<AxiosResponse<BaseResponse<EventWaiting[]>>> => this.api.get('/manager/events-waiting', {
        params: {
          start,
          end,
          filter,
        },
      }),
      validate: (id: number): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/events-waiting/event-waiting/${id}/validate`),
      cancel: (id: number): Promise<AxiosResponse<BaseResponse>> => this.api.post(`/manager/events-waiting/event-waiting/${id}/cancel`),
      fetchAvailableProviders: (needs: EventWaitingNeeds): Promise<AxiosResponse<BaseResponse<EventWaitingAvailableProvider[]>>> => this.api.post(`/manager/events-waiting/${needs.eventWaitingId}/available-providers`, needs),
    },
  };

  contract = {
    list: (): Promise<AxiosResponse<BaseResponse<Contract[]>>> => this.api.get('/manager/contract'),
    get: (id: number): Promise<AxiosResponse<BaseResponse<Contract>>> => this.api.get(`/manager/contract/${id}`),
  };
}

export default new Api();
